import React, { useState } from "react";
import RoundDropdown from "../projects/round_dropdown";
import styled from "styled-components";

const UpdatedCheckboxMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: "Active Clients",
    count: 253,
  });

  const options = [
    { label: "Active Clients", count: 253 },
    { label: "Inactive Clients", count: 60 },
    { label: "All Clients", count: 313 },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <DropdownContainer>
      {/* Dropdown Selector */}
      <DropdownSelector onClick={toggleDropdown}>
        <span>
          {selectedOption.label} ({selectedOption.count})
        </span>
        <ArrowWrapper isOpen={isOpen}>
          <RoundDropdown />
        </ArrowWrapper>
      </DropdownSelector>

      {/* Dropdown Options */}
      {isOpen && (
        <DropdownOptionWrapper>
          {options.map((option) => (
            <div
              key={option.label}
              onClick={() => handleOptionClick(option)}
              style={{
                padding: "10px",
                cursor: "pointer",
                backgroundColor:
                  selectedOption.label === option.label
                    ? "#f0f8ff"
                    : "transparent",
                fontWeight:
                  selectedOption.label === option.label ? "bold" : "normal",
                color:
                  selectedOption.label === option.label ? "#007bff" : "#000",
              }}
            >
              {option.label} ({option.count})
            </div>
          ))}
        </DropdownOptionWrapper>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  width: 166px;
  font-family: Arial, sans-serif;
`;

const DropdownOptionWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const DropdownSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 39px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
`;

const ArrowWrapper = styled.span`
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  display: flex;
  align-items: center;
`;
export default UpdatedCheckboxMenu;
