import React from "react";
import styled from "styled-components";
import { Doughnut, Line } from "react-chartjs-2";

const BreakdownContainer = ({ chartData, doughnutData }) => {
  const pieData = {
    labels: doughnutData.labels,
    datasets: [
      {
        label: "My First Dataset",
        data: doughnutData.present_data,
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(137, 123, 91)",
          "rgb(255, 206, 86)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const lineData = {
    labels: chartData.labels,
    datasets: [
      {
        label: "My First Dataset",
        data: chartData?.datasets?.map((datapoint) => datapoint.total_day_data),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  return (
    <BreakdownWrapper>
      <CapacityBreakdownContainer>
        <CapacityTitle>Capacity Breakdown</CapacityTitle>
        <CapacityPie>
          <Doughnut data={pieData} />
        </CapacityPie>
        <CapacityHours>
          There are <span>{doughnutData.present_total_hours}</span> hours booked
          this period compared to{" "}
          <span>{doughnutData.historical_total_hours ?? 0}</span> last period.
        </CapacityHours>
      </CapacityBreakdownContainer>
      <CapacityOverTimeContainer>
        <CapacityOverTimeNavigation>
          <COTWrapper>
            <CapacityTitle>Capacity Over Time</CapacityTitle>
            <DateRange>XX/XX/XXXX - XX/XX/XXXX</DateRange>
          </COTWrapper>
          <COTDropdown>dropdown</COTDropdown>
        </CapacityOverTimeNavigation>
        <CapacityOverTimeChart>
          <Line
            data={lineData}
            options={{
              scales: {
                yAxis: {
                  min: 0,
                  max: 100,
                },
              },
              aspectRatio: 2.5,
              maintainAspectRatio: false,
            }}
          />
        </CapacityOverTimeChart>
      </CapacityOverTimeContainer>
    </BreakdownWrapper>
  );
};

const BreakdownWrapper = styled.div`
  width: Fill (1, 344px) px;
  height: Hug (498px) px;
  gap: 8px;
  opacity: 0px;
  display: flex;
`;

const ArrowWrapper = styled.div`
  width: 28px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border-radius: 5px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
`;

const CapacityBreakdownContainer = styled.div`
  width: 368px;
  height: 498px;
  padding: 24px 32px 24px 32px;
  gap: 32px;
  border-radius: 5px;
  opacity: 0px;
  text-align: center;
  border: 1px solid #d7d7d7;
`;

const CapacityOverTimeContainer = styled.div`
  width: 100%;
  height: 498px;
  padding: 24px;
  gap: 24px;
  border-radius: 5px;
  opacity: 0px;
  border: 1px solid #d7d7d7;
`;

const CapacityOverTimeChart = styled.div`
  height: 400px;
`;
const CapacityTitle = styled.div`
  width: 304px;
  height: 25px;
  gap: 0px;
  opacity: 0px;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 600;
`;

const CapacityPie = styled.div`
  height: 312px;
`;

const CapacityHours = styled.div`
  font-family: Arial;
  font-size: 15px;
  font-weight: 400;

  > span {
    font-family: Arial;
    font-size: 15px;
    font-weight: 700;
    color: #519acc;
  }
`;

const CapacityOverTimeNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const COTWrapper = styled.div``;

const COTDropdown = styled.div``;

const DateRange = styled.span`
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
`;
export default BreakdownContainer;
