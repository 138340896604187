import React from "react";
import styled from "styled-components";

const TeamCapacity = ({ teamData }) => {
  return (
    <TeamCapacityContainer>
      <Title>Team Capacity</Title>
      {teamData.map((team, index) => (
        <TeamCard key={index}>
          <CircularProgress>100%</CircularProgress>
          <TeamDetails>
            <h3>{team.team_name}</h3>
            <p>{team.team_members} Members</p>
          </TeamDetails>
          <Metrics>
            <span>
              <strong>Booked</strong>
              {team.team_total.booked}
            </span>
            <span>
              <strong>Vacation</strong>
              {team.team_total.vacation}
            </span>
            <span>
              <strong>Unavailable</strong>
              {team.team_total.unavailable}
            </span>
            <span>
              <strong>Available</strong>
              {team.team_total.available}
            </span>
          </Metrics>
        </TeamCard>
      ))}
    </TeamCapacityContainer>
  );
};

const TeamCapacityContainer = styled.div`
  padding: 24px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  max-height: 450px;
  overflow-y: auto;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 0px;
`;

const TeamCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CircularProgress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 4px solid #519acc;
  color: #519acc;
  font-weight: bold;
  font-size: 14px;
`;

const TeamDetails = styled.div`
  flex: 1;
  margin-left: 16px;
  font-family: Manrope;

  h3 {
    font-size: 15px;
    margin: 0;
    font-weight: 700;
  }

  p {
    margin: 4px 0 0;
    font-size: 14px;
    font-weight: 400;
    font-family: Arial;
  }
`;

const Metrics = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  font-size: 14px;
  font-family: Manrope;

  span {
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      font-weight: 600;
    }
  }
`;

export default TeamCapacity;
