import React, { useEffect, useState } from "react";
import moment from "moment";
import MonthChart from "./month_chart";
import WeekChart from "./week_chart";
import UsersChart from "./user_chart";
import styled from "styled-components";
import { Title } from "chart.js";
// import SingleCheckboxMenu from "../common/single_checkbox_menu";
import UpdatedCheckboxMenu from "../common/UpdatedCheckboxMenu";
import Arrow from "../icons/arrow";
import BreakdownContainer from "./breakdown_container";
import TeamCapacity from "./team_capacity";
import IndividualCapacity from "./individual_capacity";
import axios from "axios";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import RoundDropdown from "../projects/round_dropdown";

const ChartContainer = (props) => {
  // console.log(props);

  const [companyChart, setCompanyChart] = useState(true);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [error, setError] = useState("");
  console.log(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
  const [chartData, setChartData] = useState([]);
  const [doughnutData, setDoughnutData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [userList, setUserList] = useState([]);

  const [focusedInput, setFocusedInput] = useState(null);

  // const userData = [
  //   { name: "John Doe", hoursAvailable: 40, bookedPercentage: 70 },
  //   { name: "Jane Smith", hoursAvailable: 35, bookedPercentage: 50 },
  //   { name: "John Doe", hoursAvailable: 40, bookedPercentage: 70 },
  //   { name: "Jane Smith", hoursAvailable: 35, bookedPercentage: 50 },
  //   { name: "John Doe", hoursAvailable: 40, bookedPercentage: 70 },
  //   { name: "Jane Smith", hoursAvailable: 35, bookedPercentage: 50 },
  //   { name: "John Doe", hoursAvailable: 40, bookedPercentage: 70 },
  //   { name: "Jane Smith", hoursAvailable: 35, bookedPercentage: 50 },
  // ];

  //make fetch request to new api
  const handleDateChange = (start, end) => {
    const maxDays = 60;
    // const startDate = moment(start);
    // const endDate = moment(end);

    // if date range is 1 day the diff breaks
    if (start.isSame(end)) {
      end.add(1, "day");
    }
    const diffDays = end.diff(start, "days");
    console.log("diff", diffDays);

    if (diffDays > maxDays) {
      setError(`The selected date range cannot exceed ${maxDays} days.`);
      console.log(`The selected date range cannot exceed ${maxDays} days.`);
      // Optionally, adjust the endDate to be 60 days after startDate
      setEndDate(moment(start).add(maxDays, "days"));
    } else {
      setError(""); // Clear any previous errors
      // Proceed with sending the request if the date range is valid
      axios
        .get(
          `/capacity_dashboard/${start.format("YYYY-MM-DD")}/${end.format(
            "YYYY-MM-DD"
          )}`
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    // const formattedStartDate = startDate.format("YYYY-MM-DD");
    // const formattedEndDate = endDate.format("YYYY-MM-DD");

    //dummy start and end dates
    const formattedStartDate = "2024-12-09";
    const formattedEndDate = "2024-12-11";
    axios
      .get(`/capacity_dashboard/${formattedStartDate}/${formattedEndDate}`, {})
      .then((res) => {
        console.log("loaded", res);
        setChartData(res.data.chart_data);
        setDoughnutData(res.data.doughnut_data);
        setTeamData(res.data.team_data);
        setUserList(res.data.user_list);
      })
      .catch((err) => console.log(err));
  }, []);

  const isDayBlocked = (day) => {
    const today = moment().startOf("day");

    // Block weekends
    const dayOfWeek = moment(day).day();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return true;
    }

    // Block dates in the future (after today)
    if (moment(day).isAfter(today)) {
      return true;
    }

    // Block days more than 60 days from the start date
    if (startDate) {
      const maxDateRange = moment(startDate).add(60, "days");
      return moment(day).isAfter(maxDateRange);
    }

    // If no start date is selected, don't block any days
    return false;
  };

  return (
    <CapacityContainer>
      <CapacityWrapper>
        <CapacityNavigation>
          <TitleContainer>
            <Logo>
              <svg
                width="28"
                height="29"
                viewBox="0 0 28 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 15.3235H13.1765V28.5H0V15.3235ZM14.8235 15.3235H28V28.5H14.8235V15.3235ZM0 0.5H13.1765V13.6765H0V0.5ZM18.1177 0.5H28V10.3823H18.1177V0.5Z"
                  fill="#519ACC"
                />
              </svg>
            </Logo>
            <TitleName>Capacity</TitleName>
            <MonthSelect>
              <DateRangePicker
                startDate={startDate}
                startDateId="your_unique_start_date_id"
                endDate={endDate}
                endDateId="your_unique_end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                  // handleDateChange(startDate, endDate);
                }}
                focusedInput={focusedInput} // This determines which input is focused
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // Update focus
                orientation="horizontal"
                numberOfMonths={1}
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(90deg)" />
                  </div>
                }
                navNext={
                  <div
                    style={{
                      right: "84px !important",
                      position: "absolute",
                      top: "16px",
                      right: "87px",
                      padding: "6px 9px",
                      lineHeight: " .78",
                      borderRadius: "3px",
                    }}
                    // className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5"
                  >
                    <RoundDropdown rotate="rotate(-90deg)" />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition="after"
                customInputIcon={<RoundDropdown />}
                isOutsideRange={isDayBlocked}
                //limit range selection to 2 months
                // maximumNights={60}
                // id="single_date_picker"
              />
              {/* <UpdatedCheckboxMenu /> */}
            </MonthSelect>
            <ArrowSelect>
              <ArrowWrapper>
                <Arrow />
              </ArrowWrapper>
              <ArrowWrapper>
                <Arrow rotate="right" />
              </ArrowWrapper>
            </ArrowSelect>
          </TitleContainer>
          <TeamContainer>
            <TeamSlider>
              <div
                id="slider-container"
                className="row"
                style={{
                  paddingLeft: "0px",
                  marginBottom: "0px",
                  marginTop: "5px",
                }}
              >
                <div>
                  <p className="col s4" style={{ marginTop: "18px" }}>
                    All
                  </p>
                  <p className="col s4" style={{ marginTop: "18px" }}>
                    Part-Time
                  </p>
                  <p className="col s4" style={{ marginTop: "18px" }}>
                    Full-Time
                  </p>
                </div>
                <div id="slider-toggle" />
              </div>
            </TeamSlider>
            <TeamSelect>Dropdown</TeamSelect>
            <ExportData>Export Data</ExportData>
          </TeamContainer>
        </CapacityNavigation>
        <DataWrapper>
          <BreakdownContainer
            chartData={chartData}
            doughnutData={doughnutData}
          />

          <UsersContainer>
            <TeamCapacity teamData={teamData} />
            <IndividualCapacity users={userList} />
          </UsersContainer>
        </DataWrapper>
        {/* <div className="col s12">
          {companyChart ? <WeekChart redraw /> : <MonthChart redraw />}
        </div>
        <div className="divider" />
        <div>
          <UsersChart />
        </div> */}
      </CapacityWrapper>
    </CapacityContainer>
  );
};

const CapacityContainer = styled.div`
  background-color: rgb(242, 242, 242);
  overflow-x: auto;
  padding: 16px;
  min-height: calc(-68px + 100vh);
  overflow: hidden;
`;

const CapacityWrapper = styled.div`
  margin: 0px 0px 8px;
  border-radius: 10px;
  border: 1px solid rgb(215, 215, 215);
  background-color: white;
  overflow: visible;
  position: relative;
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DataWrapper = styled.div`
  width: 100%;
  padding: 32px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;
const CapacityNavigation = styled.div`
  width: 100%;
  height: 77px;
  padding: 16px 32px 16px 32px;
  gap: 0px;
  border-radius: 10px 10px 0px 0px;
  border: 0px 0px 1px 0px;
  justify: space-between;
  opacity: 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d7d7d7;
`;

const TitleContainer = styled.div`
  width: Hug (381px) px;
  height: Hug (39px) px;
  gap: 16px;
  opacity: 0px;
  display: flex;
  align-items: center;
`;

const TeamContainer = styled.div`
  width: Hug (445.08px) px;
  height: Hug (45px) px;
  gap: 16px;
  opacity: 0px;
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  width: 28px;
  height: 28px;
  gap: 0px;
  opacity: 0px;
`;

const TitleName = styled.div`
  width: 87px;
  height: 27px;
  gap: 0px;
  opacity: 0px;
  //styleName: H2 - Manrope - Bold - 20;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const MonthSelect = styled.div``;

const ArrowSelect = styled.div`
  display: flex;
  gap: 4px;
`;

const TeamSlider = styled.div``;

const TeamSelect = styled.div``;

const ExportData = styled.div`
  width: Fixed (120px) px;
  height: Fixed (45px) px;
  padding: 12px 16px 12px 16px;
  gap: 0px;
  border-radius: 5px;
  justify: space-between;
  opacity: 0px;
  cursor: pointer;
  border: 1px solid #d3d3d3;
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
`;

const ArrowWrapper = styled.div`
  width: 28px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border-radius: 5px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
`;

const UsersContainer = styled.div`
  width: 100%;
  height: 536px;
  display: flex;
  justify-content: center;
  gap: 8px;
`;
export default ChartContainer;
