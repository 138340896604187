import React from "react";

const Arrow = ({ rotate }) => {
  return (
    <>
      {rotate ? (
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.709006 3.72093C-0.0711362 2.90233 -0.21298 1.56279 0.567161 0.744186C0.992693 0.297674 1.56007 -2.88309e-07 2.05652 -3.75112e-07C2.55298 -4.61915e-07 3.12035 0.223255 3.47496 0.595348L9.64518 7.06977C10.1416 7.5907 10.1416 8.33488 9.64518 8.85581L7.58844 11.014L3.40404 15.4047C2.6239 16.2233 1.34731 16.2233 0.496242 15.4047C-0.2839 14.586 -0.2839 13.2465 0.496241 12.3535L4.68064 7.96279C4.68064 7.96279 4.68064 7.96279 4.68064 7.88837L0.709006 3.72093Z"
            fill="#519ACC"
          />
        </svg>
      ) : (
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_5627_3982)">
            <path
              d="M9.29099 12.2791C10.0711 13.0977 10.213 14.4372 9.43284 15.2558C9.00731 15.7023 8.43993 16 7.94348 16C7.44702 16 6.87965 15.7767 6.52504 15.4047L0.354824 8.93023C-0.14163 8.4093 -0.14163 7.66512 0.354824 7.14419L2.41156 4.98605L6.59596 0.595349C7.3761 -0.223257 8.6527 -0.223257 9.50376 0.595348C10.2839 1.41395 10.2839 2.75349 9.50376 3.64651L5.31936 8.03721C5.31936 8.03721 5.31936 8.03721 5.31936 8.11163L9.29099 12.2791Z"
              fill="#519ACC"
            />
          </g>
        </svg>
      )}
    </>
  );
};

export default Arrow;
